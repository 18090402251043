import React from 'react';
import { Box, Flex, Link } from '@chakra-ui/react';
import { FaSquare } from 'react-icons/fa';

const Header = ({ activeSection }) => {
  return (
    <Box
      as="header"
      bgGradient="linear(to-r, gray.700, gray.900)"
      boxShadow="xl"
      py={{ base: 2, md: 4 }} // Reduced padding on mobile
      px={{ base: 4, md: 8 }} // Reduced padding on mobile
      width="100%"
      position="sticky"
      top={0}
      zIndex={10}
      borderBottom="4px solid #333"
      overflow="hidden"
    >
      <Flex justifyContent="center">
        <Link
          href="#"
          mx={{ base: 2, md: 4 }} // Reduced margin on mobile
          color="white"
          fontWeight="bold"
          fontSize={{ base: '20px', md: '28px' }} // Smaller font size on mobile
          fontFamily="Jersey10"
          _hover={{ color: 'gray.300' }}
          borderBottom={activeSection === 0 ? '2px solid white' : 'none'}
          onClick={() => window.fullpage_api.moveTo(1)}
        >
          Home
        </Link>
        <Link
          href="#"
          mx={{ base: 2, md: 4 }}
          color="white"
          fontWeight="bold"
          fontSize={{ base: '18px', md: '28px' }}
          fontFamily="Jersey10"
          _hover={{ color: 'gray.300' }}
          borderBottom={activeSection === 1 ? '2px solid white' : 'none'}
          onClick={() => window.fullpage_api.moveTo(2)}
        >
          About
        </Link>
        <Link
          href="#"
          mx={{ base: 2, md: 4 }}
          color="white"
          fontWeight="bold"
          fontSize={{ base: '18px', md: '28px' }}
          fontFamily="Jersey10"
          _hover={{ color: 'gray.300' }}
          borderBottom={activeSection === 2 ? '2px solid white' : 'none'}
          onClick={() => window.fullpage_api.moveTo(3)}
        >
          Support
        </Link>
        <Link
          href="#"
          mx={{ base: 2, md: 4 }}
          color="white"
          fontWeight="bold"
          fontSize={{ base: '18px', md: '28px' }}
          fontFamily="Jersey10"
          _hover={{ color: 'gray.300' }}
          borderBottom={activeSection === 3 ? '2px solid white' : 'none'}
          onClick={() => window.fullpage_api.moveTo(4)}
        >
          Gallery
        </Link>
      </Flex>

      {/* Animated stars */}
      {[...Array(20)].map((_, index) => (
        <FaSquare
          key={index}
          style={{
            position: 'absolute',
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            fontSize: '5px',
            color: 'white',
            animation: `moveSquare ${Math.random() * 10 + 5}s linear infinite`,
          }}
        />
      ))}
    </Box>
  );
};

export default Header;
