import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import '../styles.css';

const About = () => {
  return (
    <Box
      minH="100vh"
      bgGradient="linear(to-b, purple.700, pink.500, gray.900)"
      color="white"
      p={4}
      pb={24} // Add extra padding at the bottom
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        direction="column"
        alignItems="center"
        width="100%"
        maxW="800px"
        textAlign="center"
      >
        <h1 className="page-title">Zero Day</h1>
        <div className="promo-text">
          <p>
            Zero Day is a post-apocalyptic puzzle game where you learn about Earth's destruction and humanity's survival on a spaceship. 
            Explore the depths of a universe torn apart by high winds, solve intricate puzzles, and discover the fate of humanity.
          </p>
        </div>

        <h2 className="section-header">Meet Your AI Companion: Rebecca</h2>
        <div className="section-placeholder">
          <Box display="flex" justifyContent="center">
            <Image
              src="/images/AI_Rebecca.png"
              alt="AI Rebecca"
              className="promo-image"
              my={8}
            />
          </Box>
          <Box className="promo-text" maxW="600px" textAlign="center" mt={4}>
            <p>
              Rebecca, your AI companion, is here to guide you through the darkest corners of the ship. Need help solving a puzzle? Rebecca's got your back. But can you trust her?
            </p>
          </Box>
        </div>
        
        <h2 className="section-header">The Main Character</h2>
        <div className="section-placeholder">
          <Box display="flex" justifyContent="center">
            <Image
              src="/images/MainCharacter.png"
              alt="Main Character"
              className="promo-image"
              my={8}
            />
          </Box>
          <Box className="promo-text" maxW="600px" textAlign="center" mt={4}>
            <p>
              You awaken aboard a spaceship, alone and unsure of your surroundings. As a young boy, you must navigate through the ship, solving puzzles and uncovering the truth about Earth's fate and humanity's future.
            </p>
          </Box>
        </div>

        <h2 className="section-header">Your DNA is Your Key</h2>
        <div className="section-placeholder">
          <Box display="flex" justifyContent="center">
            <Image
              src="/images/Player_DNA_Key.png"
              alt="Player DNA Key"
              className="promo-image"
              my={8}
            />
          </Box>
          <Box className="promo-text" maxW="600px" textAlign="center" mt={4}>
            <p>
              Your DNA is your key—literally. Unlock secure areas, access personal quarters, and navigate the ship with your unique DNA key. But remember, security comes at a price.
            </p>
          </Box>
        </div>

        <h2 className="section-header">When will it be released?</h2>
        <div className="promo-text">
          <p>
            Zero Day: The Game will first be released on iOS through the App Store. It is planned for release in October 2024.
            It's an epic saga set in a beautifully crafted 2D world that promises to captivate players with its intriguing storyline and challenging puzzles.
          </p>
        </div>

        <div className="section-placeholder">
          <Box display="flex" justifyContent="center">
            <Image
              src="/images/MainCharacterGif.gif"
              alt="Main Character Animation"
              className="promo-image-small"
              my={8}
            />
          </Box>
        </div>

        <h2 className="section-header">Explore the Ship's AI</h2>
        <div className="section-placeholder">
          <Box display="flex" justifyContent="center">
            <Image
              src="/images/Room9MainFrame.png"
              alt="Ship's AI"
              className="promo-image"
              my={8}
            />
          </Box>
          <Box className="promo-text" maxW="600px" textAlign="center" mt={4}>
            <p>
              The Ship's AI is the heart of the mission. Here, simulations run non-stop, trying to revive Earth—or find a new home. But is the AI really working for humanity's best interest?
            </p>
          </Box>
        </div>

        <h2 className="section-header">The Mission Continues</h2>
        <div className="section-placeholder">
          <Box display="flex" justifyContent="center">
            <Image
              src="/images/promo4.png"
              alt="Promo Image 4"
              className="promo-image"
              my={8}
            />
          </Box>
          <Box className="promo-text" maxW="600px" textAlign="center" mt={4}>
            <p>
              Every puzzle you solve brings you closer to the truth. Discover the logs, the captain’s notes, and the real mission of this ship—saving the remnants of Earth's life.
            </p>
          </Box>
        </div>

        <h2 className="section-header">Game Publisher</h2>
        <div className="promo-text">
          <p>
            The game is published by "Technically A Game," a Sweden-based game publisher. 
            Visit their website at <a href="https://technicallyagame.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'underline', marginLeft: '4px' }}>Technically A Game</a> <br></br>
            to discover more games developed by them.
          </p>
        </div>

        <div className="section-placeholder">
      {/* Add the app icon here */}
      <Box display="flex" justifyContent="center">
      <Image
  src="/appicon.png"
  alt="Zero Day App Icon"
  className="promo-image-large"
  my={8}
  boxSize="100px" // Example of setting a fixed size
/>
      </Box>

        <Box className="promo-text" maxW="600px" textAlign="center" mt={4}>
          <p>
            This is the official app icon for Zero Day on the iOS App Store. Look for it when the game launches!
          </p>
        </Box>
      </div>

        <h2 className="section-header">Game Creator</h2>
        <div className="promo-text">
          <p>
            I am Peter Sandkvist, a web and app developer, game creator, and developer. 
            My passion is creating engaging experiences through interactive storytelling and challenging gameplay.
          </p>
        </div>

        {/* Add picture4 below the Game Creator text */}
        <div className="section-placeholder">
          <Box display="flex" justifyContent="center">
            <Image
              src="/images/picture4.PNG"
              alt="Game Creator"
              className="promo-image"
              my={8}
            />
          </Box>
        </div>

        <h2 className="section-header">Who Was Involved?</h2>
        <div className="section-placeholder">
          <Box display="flex" justifyContent="center">
            <Image
              src="/images/promo5.png"
              alt="Promo Image 5"
              className="promo-image"
              my={8}
            />
          </Box>
          <Box className="promo-text" maxW="600px" textAlign="center" mt={4}>
            <p>
              The music for Zero Day was composed by Joakim Bradman, who brought the world to life with his captivating soundscapes. 
              The stunning art and visuals were crafted by Andrea Hamdan, whose creativity and attention to detail made the game visually appealing and immersive.
            </p>
          </Box>
        </div>

        {/* Add picture5 under Join the Adventure */}
        <h2 className="section-header">Join the Adventure</h2>
        <Box className="promo-text" maxW="800px" textAlign="center" mt={8}>
          <p>
            "Zero Day" is a captivating sci-fi puzzle adventure where you uncover the dark secrets of humanity’s last hope—a ship carrying the DNA and seeds of all life on Earth. Will you save humanity or witness its final downfall?
          </p>
        </Box>
        <div className="section-placeholder">
          <Box display="flex" justifyContent="center">
            <Image
              src="/images/picture5.PNG"
              alt="Join the Adventure"
              className="promo-image"
              my={8}
            />
          </Box>
        </div>

        {/* Add pictures6, 7, and 8 between the last three text sections */}
        <Box className="promo-text" maxW="800px" textAlign="center" mt={8}>
          <p>
            🌌 In a future where Earth is lost to catastrophic winds, a young boy awakens alone on a spaceship—humanity's last hope. Guided by the AI companion, Rebecca, you must solve puzzles, navigate through ten mysterious rooms, and uncover the dark secrets of the ship. But beware, not everything is as it seems... Who can you trust? Can you save the remnants of Earth and find a new home?
          </p>
        </Box>
        <div className="section-placeholder">
          <Box display="flex" justifyContent="center">
            <Image
              src="/images/picture6.PNG"
              alt="Story Section 1"
              className="promo-image"
              my={8}
            />
          </Box>
        </div>

        <Box className="promo-text" maxW="800px" textAlign="center" mt={8}>
          <p>
            🔍 Imagine waking up alone on a spaceship, with no memory of how you got there, only to discover that you might be humanity's last hope. Zero Day takes you on a thrilling adventure where you'll solve intricate puzzles, unlock the mysteries of a crew in cryosleep, and navigate the consequences of AI-driven decisions that might save or doom us all.
          </p>
        </Box>
        <div className="section-placeholder">
          <Box display="flex" justifyContent="center">
            <Image
              src="/images/picture7.PNG"
              alt="Story Section 2"
              className="promo-image"
              my={8}
            />
          </Box>
        </div>

        <Box className="promo-text" maxW="800px" textAlign="center" mt={8}>
          <p>
            Embark on a gripping sci-fi puzzle adventure in Zero Day. Wake up on a ship filled with the DNA of Earth's life, solve puzzles, and uncover the fate of humanity. Can you lead us to a new home?
          </p>
        </Box>
        <div className="section-placeholder">
          <Box display="flex" justifyContent="center">
            <Image
              src="/images/picture8.PNG"
              alt="Story Section 3"
              className="promo-image"
              my={8}
            />
          </Box>
        </div>

      </Flex>
    </Box>
  );
};

export default About;
