// src/App.js
import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Add Routes and Route from react-router-dom
import ReactFullpage from '@fullpage/react-fullpage';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Support from './pages/Support';
import Gallery from './pages/Gallery';
import PrivacyPolicy from './pages/PrivacyPolicy'; // Import PrivacyPolicy component
import RouteWrapper from './components/RouteWrapper'; // Import RouteWrapper

function App() {
  const [showFooter, setShowFooter] = React.useState(false);
  const [activeSection, setActiveSection] = React.useState(0);

  const handleLeave = (origin, destination) => {
    setActiveSection(destination.index); // Update the active section index
    if (destination.index === 3) {
      setShowFooter(true);
    } else {
      setShowFooter(false);
    }
  };

  return (
    <ChakraProvider>
      <Router>
        <RouteWrapper>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header activeSection={activeSection} />
                  <ReactFullpage
                    scrollingSpeed={1000}
                    onLeave={handleLeave}
                    render={() => {
                      return (
                        <ReactFullpage.Wrapper>
                          <div className="section">
                            <Home />
                          </div>
                          <div className="section">
                            <About />
                          </div>
                          <div className="section">
                            <Support />
                          </div>
                          <div className="section">
                            <Gallery />
                          </div>
                        </ReactFullpage.Wrapper>
                      );
                    }}
                  />
                  {/* Footer should not be conditionally rendered in Fullpage sections */}
                  {showFooter && <Footer />}
                </>
              }
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </RouteWrapper>
      </Router>
    </ChakraProvider>
  );
}


export default App;
