// src/components/RouteWrapper.js
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';
import { ChakraProvider } from '@chakra-ui/react';

const RouteWrapper = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/privacy-policy') {
      setIsLoading(false); // Skip loading screen if navigating to Privacy Policy
    } else {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [location.pathname]);

  if (isLoading && location.pathname !== '/privacy-policy') {
    return <LoadingScreen />;
  }

  return <>{children}</>;
};

export default RouteWrapper;
